import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { IProfilesSessionResponse, SideBarStates } from 'services'

export const atomChangeschoolMenuStateSchools = atom<IProfilesSessionResponse | null>(null)
export const atomActiveChangeClass = atom<boolean>(false)
export const atomActiveHeaderConfigs = atom<boolean>(false)
export const atomActiveMenuSidebar = atom<boolean>(false)
export const atomCurrentActiveMenuSidebar = atomWithStorage<SideBarStates>('currentActiveMenuSidebar', 'home')
export const atomActiveModalConfirmation = atom<boolean>(false)
